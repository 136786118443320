import {
  computed,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeUnmount,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    onDepth1Item: computed(() => getters['nav/getOnDepth1Item']),
    categoryList: computed(() => getters['nav/getCategoryList']),
    category: [],
    serverDt: '',
    sellerFee: 0,
    computedSellerFee: computed(() =>
      Math.floor(state.sellerFee * state.params.price)
    ),
    expectedTotalAmount: computed(
      () => Number(state.params.price) - state.computedSellerFee
    ),
    params: {
      categoryId: '',
      title: '',
      price: '',
      commonPrice: '',
      contents: '',
      searchWord: '',
      reqKakao: '0',
      reqPhone: '0',
      reqEmail: '0',
      type: '0'
    },
    inputFile: '',
    files: [],
    pinNumberList: [],
    goodsList: [],
    itemList: [],
    hopeDetail: computed(() => getters['party/getHopeDetail']),
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const fnCategoryList = async () => {
    const res = await proxy.$MarketSvc.postMarketAddForm()
    if (res.resultCode === '0000') {
      state.category = res.category.filter(item => item.categoryId !== '2003')
      state.serverDt = res.serverDt
      state.sellerFee = res.sellerFee
    } else if (res.resultCode === '0001') {
      // data 없음
      state.category = [
        {
          categoryId: '2001',
          categoryNm: '상품권'
        },
        {
          categoryId: '2002',
          categoryNm: '티켓'
        }
      ]
    } else {
      alert(res.resultMsg)
    }
  }
  const fnSave = async () => {
    if (!(await isValid())) return
    const request = {
      ...state.params,
      pinList: state.itemList
    }

    const params = {
      request: JSON.stringify(request),
      file: state.files
    }
    const res = await proxy.$MarketSvc.postMarketAdd(params)
    if (res.resultCode === '0000') {
      alert('상품이 정상 등록되었습니다.')
      if (!proxy.$Util.isEmpty(route.query.isList) && route.query.isList > 0) {
        const keys = proxy.$_.keyBy(state.categoryList, 'categoryID')
        const depth1 = keys[proxy.$ConstCode.CATEGORY_VALUE['1000'].id] // 영상
        await dispatch('nav/updateOnDepth1Item', {
          onDepth1Item: depth1
        })
        const key2s = proxy.$_.keyBy(depth1.categoryList, 'categoryID')
        await dispatch('nav/updateOnDepth2Item', {
          onDepth2Item: key2s[params.categoryId]
        })
        await router.push({ path: '/market/list' })
      } else {
        await router.go(-1)
      }
    } else {
      alert(res.resultMsg)
    }
  }
  const { fnMyInfo, fnUpdateAuthType } = CommonFunction(state)
  const isValid = async () => {
    const reqOptions = {
      reqKakao: state.params.reqKakao,
      reqPhone: state.params.reqPhone,
      reqEmail: state.params.reqEmail
    }
    if (
      proxy.$Util.isEmpty(state.userData) ||
      proxy.$Util.isEmpty(state.userData.authType) ||
      Number(state.userData.authType) < 4
    ) {
      alert(
        '상품등록을 위해 내 정보 수정 > 휴대폰 인증 및 계좌번호 등록을 해주세요.'
      )
      await fnMyInfo()
      return false
    }
    if (proxy.$Util.isEmpty(state.params.categoryId)) {
      alert('카테고리를 선택해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.title)) {
      alert('제목을 입력해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.commonPrice)) {
      alert('개당 소비자 가격을 입력해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.price)) {
      alert('개당 판매 가격을 입력해주세요.')
      return false
    }
    if (Number(state.params.commonPrice) > 100000000) {
      alert('소비자가는 1억까지만 등록가능합니다.')
      return false
    }
    if (Number(state.params.price) > 100000000) {
      alert('판매가는 1억까지만 등록가능합니다.')
      return false
    }
    if (Number(state.params.price) < 1000) {
      alert('판매가는 1000원 이상으로 등록해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.contents)) {
      alert('상품 소개글을 입력해주세요.')
      return false
    }
    if (state.params.type == '0' && proxy.$Util.isEmpty(state.itemList)) {
      alert('핀번호를 입력해주세요.')
      return false
    }
    if (state.params.type == '1' && proxy.$Util.isEmpty(state.itemList)) {
      alert('상품 정보를 입력해주세요.')
      return false
    }
    if (
      state.params.type == '1' &&
      Object.keys(reqOptions).every(key => reqOptions[key] == 0)
    ) {
      alert('구매자 정보요청을 선택해주세요.')
      return false
    }

    return true
  }
  const fnCancel = async () => {
    await router.go(-1)
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  onBeforeUnmount(async () => {
    await dispatch('party/updateHopeDetail', { hopeDetail: {} })
  })

  const fnUpdate = files => {
    state.files = files
  }

  const fnRemove = files => {
    state.files = files
  }

  const fnUpdateItemList = itemList => {
    if (state.params.type == '0') {
      state.pinNumberList = itemList
      state.itemList = [...state.pinNumberList]
    } else if (state.params.type == '1') {
      state.goodsList = itemList
      state.itemList = [...state.goodsList]
    }
  }

  const maskingQuantityNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value < 1) e.target.value = ''
    if (e.target.value > 999) e.target.value = 999
    state.params.quantity = e.target.value
  }

  const maskingPriceNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value > 100000000) e.target.value = 100000000
    state.params.price = e.target.value
  }

  const maskingCommonPriceNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value > 100000000) e.target.value = 100000000
    state.params.commonPrice = e.target.value
  }

  watch(
    () => state.params.type,
    () => {
      if (state.params.type == '0') {
        state.itemList = [...state.pinNumberList]
      } else if (state.params.type == '1') {
        state.itemList = [...state.goodsList]
      }
    }
  )

  const init = async () => {
    await fnCategoryList()
    if (!proxy.$Util.isEmpty(state.hopeDetail)) {
      state.params.categoryId = state.hopeDetail.categoryId
      state.params.title = state.hopeDetail.title
      state.params.usePeriod = state.hopeDetail.usePeriod
      state.params.dailyPrice = Number(
        (state.hopeDetail.minPrice / state.hopeDetail.usePeriod).toFixed(0)
      )
    }
  }
  init()
  return {
    ...toRefs(state),
    fnCategoryList,
    fnSave,
    fnCancel,
    fnCallback,
    fnUpdate,
    fnRemove,
    maskingQuantityNumber,
    maskingPriceNumber,
    maskingCommonPriceNumber,
    fnUpdateItemList
  }
}
