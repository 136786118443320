<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>마켓 등록하기</span>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl essen">카테고리</div>
                  <div class="form_in">
                    <label class="select">
                      <select v-model="params.categoryId">
                        <option :value="''">카테고리를 선택해 주세요.</option>
                        <option
                          v-for="(cate, index) in category"
                          :key="index"
                          :value="cate.categoryId"
                          >{{ cate.categoryNm }}</option
                        >
                      </select>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">상품명</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="상품명을 입력해 주세요(100자 이내)"
                        v-model="params.title"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">소비자가(개당)</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="number"
                        inputmode="decimal"
                        pattern="\d*"
                        placeholder="개당 소비자가"
                        @input="maskingCommonPriceNumber"
                        :value="params.commonPrice"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">판매가(개당)</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="number"
                        inputmode="decimal"
                        pattern="\d*"
                        placeholder="개당 판매가"
                        @input="maskingPriceNumber"
                        :value="params.price"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">플랫폼 이용료(개당)</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        :value="
                          $Util.formatNumber2(computedSellerFee, {
                            unit: ' 원'
                          })
                        "
                        disabled
                      />
                    </label>
                  </div>
                  <div class="hint">
                    * 플랫폼 이용료 할인이벤트 진행 중({{
                      Math.floor(sellerFee * 100)
                    }}% 적용)
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">정산 예정금액(개당)</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        :value="
                          $Util.formatNumber2(expectedTotalAmount, {
                            unit: ' 원'
                          })
                        "
                        disabled
                      />
                    </label>
                  </div>
                </li>
                <file-input-li
                  :max-count="5"
                  :read-only="files.length > 5"
                  :value="files"
                  @update="fnUpdate($event)"
                  @remove="fnRemove($event)"
                />
                <li class="form_li">
                  <div class="form_ttl essen">상품 소개 글 및 주의사항</div>
                  <div class="form_in">
                    <label>
                      <textarea
                        placeholder="판매하실 상품에 대한 상세설명을 기입해주세요. 자세한 사용방법(등록방법) 및 사용처에 대한 설명 등"
                        :maxlength="1000"
                        v-model="params.contents"
                      ></textarea>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="page_tab">
                    <div class="btn_wrap" style="padding: 1rem 0 0 0;">
                      <button
                        :class="{ on: params.type == '0' }"
                        @click="() => (params.type = '0')"
                      >
                        쿠폰번호(PIN)등록
                      </button>
                      <button
                        :class="{ on: params.type == '1' }"
                        @click="() => (params.type = '1')"
                      >
                        구매자 정보요청
                      </button>
                    </div>
                  </div>
                </li>
                <PinNumberAddList
                  v-if="params.type == '0'"
                  :maxCount="5"
                  :editPinList="itemList"
                  @update="fnUpdateItemList($event)"
                />
                <GoodsAddList
                  v-if="params.type == '1'"
                  :maxCount="5"
                  :editGoodsList="itemList"
                  @update="fnUpdateItemList($event)"
                />
                <li v-if="params.type == '1'" class="form_li">
                  <div class="form_ttl essen">구매자 정보요청</div>
                  <div class="form_in">
                    <div class="inline">
                      <div
                        class="option_btn"
                        :class="{ on: params.reqKakao == '1' }"
                        @click="
                          () =>
                            (params.reqKakao =
                              params.reqKakao == '0' ? '1' : '0')
                        "
                      >
                        <button>카카오톡ID</button>
                      </div>
                      <div
                        class="option_btn"
                        :class="{ on: params.reqPhone == '1' }"
                        @click="
                          () =>
                            (params.reqPhone =
                              params.reqPhone == '0' ? '1' : '0')
                        "
                      >
                        <button>휴대폰번호</button>
                      </div>
                      <div
                        class="option_btn"
                        :class="{ on: params.reqEmail == '1' }"
                        @click="
                          () =>
                            (params.reqEmail =
                              params.reqEmail == '0' ? '1' : '0')
                        "
                      >
                        <button>이메일</button>
                      </div>
                    </div>
                    <div class="hint">
                      상품 전달을 위해 필요한 구매자 정보를 선택해주세요.
                    </div>
                    <div class="hint">
                      구매자가 작성한 정보는 판매내역 상세에서 확인 가능합니다.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">검색어 설정</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="검색어, 검색어, 검색어, 검색어, 검색어"
                        v-model="params.searchWord"
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnCancel">취소</button>
              <button class="btn" @click="fnSave">등록</button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>유의사항</dt>
                <dd>
                  <div>
                    * 플랫폼 이용료는 총 판매금액의 7% 입니다.(총 판매가 * 0.07)
                  </div>
                  <div>
                    * 개인 채널 홍보 및 개인간의 거래를 유도할 경우 서비스
                    이용이 불가능 할 수 있습니다.
                  </div>
                  <div>
                    * 미사용 상품인지 확인 후 등록 해주세요.
                  </div>
                  <div>
                    * 판매자가 등록한 상품정보 및 거래에 대한 책임은 판매자에게
                    있으며, 허위 상품 등록 시 형사 처벌을 받을 수 있습니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import FileInputLi from '@/components/views/market/write/file-input-li'
import PinNumberAddList from '@/components/views/market/write/PinNumberAddList'
import GoodsAddList from '@/components/views/market/write/GoodsAddList'

export default {
  name: 'market-write-index',
  components: {
    FileInputLi,
    PinNumberAddList,
    GoodsAddList
  },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
